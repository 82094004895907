@import '../../styles/Vars';
@import '../../styles/Helpers';

.blaundus-banner {
  background-color: $primary;
  color: $light;
  margin-bottom: 80px;
  position: relative;
  padding-top: 230px;
  padding-bottom: 150px;

  .blaundus-banner-title {
    font-weight: 800;
    text-transform: uppercase;
    font-size: $font-size-14;

    @include media-breakpoint-up-medium {
      font-size: $font-size-46;
      line-height: 82px;
    }
  }

  .blaundus-banner-image {
    position: absolute;
    right: 0;

    @include media-breakpoint-up-medium {
      max-width: 52%;
      bottom: -65px;
    }
  }

  @include media-breakpoint-down-medium {
    padding-top: 100px;
  }
}

.title {
  &-blaundus {
    font-weight: 600;
    font-size: $font-size-14;
    letter-spacing: 2.5px;
    margin-top: 37px;
    margin-bottom: 20px;

    > span {
      color: $secondary;
      padding-left: 10px;

      span {
        font-weight: 300;
      }
    }

    @include media-breakpoint-up-medium {
      font-size: $font-size-44;
      line-height: 88px;
      letter-spacing: 5.8px;
      margin-top: 100px;
      margin-bottom: 0;

      span {
        font-size: $font-size-54;
      }
    }
  }

  &-specifications {
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    font-size: $font-size-4;
    letter-spacing: 1px;

    @include media-breakpoint-up-medium {
      letter-spacing: 2px;
      font-size: $font-size-24;
    }
  }
}

.text {
  &-blaundus {
    font-weight: 300;
    line-height: 26px;
    font-size: $font-size-2;

    @include media-breakpoint-up-medium {
      margin-top: 100px;
      font-size: $font-size-12;
      line-height: 38px;
    }
  }
}

.to-layout-mobile {
  text-transform: uppercase;
  color: $secondary;
  font-size: $font-size--2;
  letter-spacing: 2px;
  line-height: 1;
}

.blaundus-slider {
  padding-left: 25px;
  margin-top: 30px;

  .img-zoom-button {
    bottom: 10px;
  }

  .slider-image {
    height: 200px;
    background-position: center;
    background-size: cover;
  }

  @include media-breakpoint-up-medium {
    padding-left: 10%;
    margin-top: 130px;

    .img-zoom-button {
      bottom: 80px;
    }

    .slider-image {
      height: 450px;
    }

    .slick-arrow {
      bottom: 23px;
    }
  }
}

.specs-header {
  margin-top: 50px;

  @include media-breakpoint-up-medium {
    margin-top: 125px;
  }
}

.blaundus-spec-table {
  margin: 70px 0 120px;
}

.blaundus-single-image-slider {
  .slick-slide {
    > div {
      margin-right: 30px;

      @include media-breakpoint-down-medium {
        margin-right: 20px;
      }
    }

    .slider-title {
      padding-left: 10%;
      padding-bottom: 2%;
      font-size: $font-size-14;
    }
  }

  .slick-arrow {
    bottom: auto !important;
    top: -100px !important;
  }

  .slick-next {
    right: 230px !important;
  }

  .slick-prev {
    right: 260px !important;
  }

  .slider-image {
    background-position: center;
    background-size: cover;
    height: 200px;
  }

  @include media-breakpoint-up-medium {
    .img-zoom-button {
      bottom: 20px;
    }

    .slider-image {
      height: 600px;
    }
  }
}
