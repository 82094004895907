@import '../../styles/Vars';
@import '../../styles/Helpers';

.container-fluid.contact-wrapper {
  padding-left: 0;
  padding-right: 0;

  .contact-text {
    padding: 30px 25px 115px 25px;
  }

  @include media-breakpoint-up-medium {
    padding-left: 98px;
    padding-right: 98px;
    padding-top: 120px;

    .contact-text {
      padding: 0 0 90px 0;
    }
  }
}

.contact-header {
  font-size: $font-size--4;
  font-weight: 800;
  opacity: 0.4;
  text-transform: uppercase;

  @include media-breakpoint-down-medium {
    margin-top: 25px;
  }
}

.work-w-us {
  padding-top: 130px;
}

.contact-title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: $font-size-14;
  letter-spacing: 1.3px;
  margin-bottom: 20px;

  @include media-breakpoint-up-medium {
    font-size: $font-size-34;
  }
}

.contact-description {
  @include media-breakpoint-up-medium {
    font-size: $font-size-4;
    line-height: 28px;
    max-width: 80%;
    /* margin-bottom: 230px; */
  }
}

.contact-details {
  line-height: 20px;
  font-size: $font-size--2;
  font-weight: 300;
}
