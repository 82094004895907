@import '../../styles/Vars';
@import '../../styles/Helpers';

.react-360-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

 /*  @include media-breakpoint-up-medium {
    height: 100vh;
  } */
}

.react360 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  user-select: none;
  cursor: grab;
}

.sequence-inner {
  position: absolute;
  bottom: 5%;
  left: 20%;

  .three-sixty-inner {
    position: relative;
    color: $light;
    height: 100%;
    display: flex;
    align-items: flex-end;

    > div {
      z-index: 12;
    }

    .ts-icon {
      margin-bottom: 30px;

      img {
        height: 60px;
      }

      span {
        font-weight: 300;
        font-size: $font-size-44;
        padding-left: 15px;
      }
    }

    h2 {
      font-size: $font-size-34;
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: 800;
      margin: 0;
    }

    p {
      font-size: $font-size-14;
    }
  }
}
