@import '../../styles/Vars';
@import '../../styles/Helpers';

.interior-sneak-wrapper {
  padding-left: 25%;
  margin-top: 100px;

  h2 {
    text-transform: uppercase;
    font-size: $font-size-24;
    font-weight: 800;
    letter-spacing: 4px;
    margin-bottom: 16px;
  }

  p {
    color: $dark-alternate;
    font-size: $font-size-2;
    line-height: 26px;
    font-weight: 300;
    margin: 0;
  }

  .sneak-item {
    height: 54vh;

    &:first-child {
      width: 70%;
    }

    &:last-child {
      width: calc(30% - 50px);
    }
  }

  .sneak-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
  }

  .sneak-label {
    font-size: $font-size-5;
    letter-spacing: 2px;
    font-weight: 300;
    margin-top: 15px;
  }

  .slider-filter {
    button {
      font-size: $font-size--2;
      letter-spacing: 2.3px;
    }

    span {
      margin: 0 10px;
    }

    @include media-breakpoint-down-medium {
      margin: 10px 0 0;

      button:first-child {
        padding-left: 0;
      }
    }

    @include media-breakpoint-up-medium {
      position: absolute;
      right: 130px;
      top: -40px;
    }
  }

  .interior-gallery-wrapper {
    margin-top: 20px;
  }

  @include media-breakpoint-down-medium {
    padding-left: 8%;
    padding-right: 0;
    margin-top: 80px;

    h2 {
      font-size: $font-size-14;
    }

    p {
      font-size: $font-size-base;
      line-height: 20px;
      padding-right: 10%;
    }

    .interior-gallery-wrapper {
      margin-top: 0;
      padding-left: 0;
    }

    .interior-sneak-gallery .slick-slide > div {
      margin-right: 20px;
    }
  }
}

.interior-sneak-gallery {
  .slick-slide > div {
    margin-right: 50px;
  }

  .slider-item {
    position: relative;

    .img-zoom-button {
      @include media-breakpoint-up-medium {
        bottom: 20px;
      }
    }
  }

  .slick-arrow {
    img {
      height: 25px;
    }
  }

  .slick-prev {
    top: -67px;
    right: 80px !important;
    left: auto;
  }

  .slick-next {
    top: -67px;
    right: 40px !important;
  }

  .slider-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: grab;
    height: 200px !important;

    @include media-breakpoint-up-medium {
      height: 60vh !important;
    }
  }

  @include media-breakpoint-down-medium {
    margin-top: 20px;

    .slider-title {
      display: none;
    }
  }
}
