@import '~bootstrap/scss/bootstrap';
@import '../../styles/Vars';
@import '../../styles/Helpers';

.news-event-wrapper {
  margin-top: 150px;
}

.news-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  .title {
    &-news {
      font-size: $font-size-54;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 3px;
      line-height: 1;
    }
  }
}

.news-slider {
  .slick-list {
    width: 120%;
  }

  .slick-slide {
    &.slick-current {
      z-index: 2;
      background-color: $light;
    }

    > div {
      margin-right: 30px;
    }

    &:not(.slick-current) {
      opacity: 0.5;
      transform: translateX(100px);
    }
  }

  .news-item {
    .news-cover {
      height: 200px;
      width: 100%;
      background-size: cover;
      background-position: center;

      @include media-breakpoint-up(md) {
        height: 200px;
      }

      @include media-breakpoint-up(lg) {
        height: 300px;
      }

      @include media-breakpoint-up-xxl {
        height: 470px;
      }
    }

    .news-tag {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      .tag-category,
      .tag-date {
        font-size: $font-size--2;
        font-weight: 800;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .tag-date {
        color: $gray-600;
      }
    }

    .news-details {
      h3 {
        font-size: $font-size-14;
        margin-top: 10px;
        line-height: 38px;
        letter-spacing: 1.7px;
        font-weight: 600;
      }

      p {
        font-size: $font-size-2;
        font-weight: 300;
        line-height: 28px;
      }
    }
  }

  .slick-track {
    display: flex;
  }

  .slick-arrow {
    right: auto;
    top: 235px;

    img {
      height: 32px;
    }
  }

  .slick-prev {
    left: 30%;
  }

  .slick-next {
    left: 33.5%;
  }
}
