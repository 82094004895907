@import '../../styles/Vars';
@import '../../styles/Helpers';

.naval-sketch {
  margin-top: 90px;
}

.naval-text {
  margin-top: 65px;
  margin-bottom: 125px;

  @include media-breakpoint-up-medium {
    margin-top: 130px;
    margin-bottom: 190px;
  }
}

.title {
  &-naval,
  &-interior,
  &-exterior {
    font-size: $font-size-14;
    line-height: 40px;
    letter-spacing: 1.3px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-32;
      line-height: 50px;
      margin-bottom: 0;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-54;
      line-height: 70px;
    }
  }

  &-interior,
  &-exterior {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.text {
  &-naval {
    font-size: $font-size-4;
    font-weight: 300;
    line-height: 28px;

    @include media-breakpoint-up-huge {
      font-size: $font-size-12;
      line-height: 38px;
    }
  }

  &-interior,
  &-exterior {
    color: $gray-600;
    font-weight: 300;
    line-height: 21px;
    font-size: $font-size--2;

    @include media-breakpoint-up-medium {
      font-size: $font-size-4;
      line-height: 32px;
      padding-bottom: 50px;
    }
  }
}

.naval-meeting-text {
  padding: 35px 25px 115px 25px;
  font-size: $font-size-4;
  font-weight: 300;

  .quote {
    font-weight: 500;
    font-style: italic;
  }

  @include media-breakpoint-up-medium {
    padding: 0 0 0 98px;
  }

  @include media-breakpoint-up-huge {
    font-size: $font-size-12;

    .quote {
      font-size: $font-size-18;
    }
  }
}

.naval-meeting-image {
  @include media-breakpoint-up-medium {
    padding: 100px 98px;
  }
}

.interior-gallery-wrapper,
.exterior-gallery-wrapper {
  .slider-image {
    background-position: center;
    background-size: cover;
    height: 180px;

    @include media-breakpoint-up-medium {
      height: 450px;
    }
  }

  .slick-arrow img {
    height: 32px;
  }

  @include media-breakpoint-down-medium {
    padding-left: 25px;
  }
}

.interior-gallery-wrapper {
  .slick-arrow {
    bottom: auto;
    top: -70px;
  }

  .img-zoom-button {
    bottom: 10px;

    @include media-breakpoint-up-medium {
      bottom: 75px;
    }
  }
}

.exterior-gallery-wrapper {
  .slick-arrow {
    bottom: auto;
    top: -90px;
  }

  .img-zoom-button {
    bottom: 10px;

    @include media-breakpoint-up-medium {
      bottom: 20px;
    }
  }
}

.naval-interior,
.naval-exterior {
  margin-top: 90px;
  margin-bottom: 40px;
}

.naval-construction {
  padding-top: 85px;
  padding-bottom: 85px;

  @include media-breakpoint-up-medium {
    padding-top: 135px;
    padding-bottom: 170px;
  }
}

.naval-construction-video {
  position: relative;

  .image-overlay {
    bottom: 10px;
  }
}

.naval-construction-title {
  font-size: $font-size-14;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 30px;

  @include media-breakpoint-up-medium {
    font-size: $font-size-26;
  }
}

.naval-filter-mobile {
  padding-left: 20px;
}
