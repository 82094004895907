@import '../../styles/Vars';
@import '../../styles/Helpers';

.home-menu {
  z-index: 109;
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  width: 17vw;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: relative;
      line-height: 1;
      margin-bottom: 20px;

      &::before {
        background-color: $alto;
        border-radius: 50%;
        content: '';
        height: 6px;
        left: 3px;
        position: absolute;
        top: 50%;
        transform: translateY(-4px);
        width: 6px;
        transform-origin: center center;
        transition: all 0.15s ease-in-out;
        z-index: 1;
      }

      &.active-element {
        &.text-light {
          button span {
            color: $secondary-light;
          }

          &::before {
            background-color: $secondary-light;
          }
        }

        &::before {
          border-radius: 0;
          height: 2px;
          width: 14px;
          transform: translateY(-1px);
        }

        button span {
          opacity: 1;
        }
      }

      &:not(.active-element):hover {
        button span {
          opacity: 1;
        }

        &::before {
          width: 12px;
          height: 12px;
          left: 0px;
          top: calc(50% - 3px);
        }
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 5px 25px;
    z-index: 2;
    position: relative;
    text-align: left;

    &:active,
    &:focus {
      outline: none;
    }

    span {
      font-size: $font-size--2;
      text-transform: uppercase;
      font-weight: 800;
      opacity: 0;
      transition: all 0.15s ease-in-out;
      letter-spacing: 2px;
    }
  }

  @include media-breakpoint-down-medium {
    display: none;
  }
}

.components-wrapper,
.slider-video-wrapper {
  z-index: 0;
  overflow: hidden;

  @include media-breakpoint-up-medium {
    height: 100vh;
  }
}

.components-overlay {
  background: linear-gradient(
    to left,
    rgba(20, 25, 30, 0),
    rgba(20, 25, 30, 0.4)
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 50vw;
  bottom: 0;
  z-index: 0;

  @include media-breakpoint-down-medium {
    display: none;
  }
}

.slider-next-button-wrapper {
  position: absolute;
  bottom: 5%;
  left: 5%;
  z-index: 1032;
}

.home-part-pre {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  top: 0;
  z-index: -1;
}
