@import '../../styles/Vars';

.news-detail-wrapper {
  margin-top: 150px;
  position: relative;

  .news-detail-item {
    .detail-image {
      background-size: cover;
      background-position: center;
      height: calc(100vh - 418px);
      width: 100%;
    }

    .detail-text {
      padding: 0 10%;
    }

    .title {
      &-news {
        font-size: $font-size-14;
        font-weight: 800;
        line-height: 1;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }

    .text {
      &-news {
        font-size: $font-size-2;
        font-weight: 300;
        line-height: 28px;
        margin-top: 40px;
      }
    }
  }

  .btn-icon-only {
    position: absolute;
    left: 5%;
    top: 0;
  }

  .slick-arrow {
    top: 0;
    right: calc(5% + 18px);
    left: auto;

    img {
      height: 32px;
    }
  }

  .slick-prev {
    right: calc(5% + 60px);
  }

  .news-footer {
    line-height: 1;
    align-items: flex-end;
  }

  .news-category {
    font-size: $font-size--2;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 800;
  }

  .news-date {
      font-size: $font-size--2;
      color: $gray-600;
  }
}
