@import '../../styles/Vars';
@import '../../styles/Helpers';

.shipyard-sneak-wrapper {
  padding-top: 150px;
  background-position: center;
  background-size: cover;
  height: 100vh;
  padding-left: 25%;
  transition: height 0.4s ease;
  position: relative;

  .shipyard-sneak-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(20, 25, 29, 0.5);
    z-index: 0;
    transition: height 0.4s ease;
  }

  .shipyard-sneak-inner {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      font-size: $font-size-14;
      color: $secondary;
      text-transform: uppercase;
      letter-spacing: 9px;
      font-weight: 600;
      margin: 0;
      line-height: 1;
    }

    h2 {
      color: $light;
      text-transform: uppercase;
      font-size: $font-size-34;
      font-weight: 800;
      letter-spacing: 3px;
    }
  }

  .slider-video-button {
    left: 0;
    position: relative;

    .alpha {
      background-color: rgba(20, 25, 29, 0.4);
    }
  }

  @include media-breakpoint-down-medium {
    padding-left: 10%;
    padding-right: 10%;

    .shipyard-sneak-inner {
      h3 {
        font-size: $font-size-4;
        letter-spacing: inherit;
        line-height: 40px;
      }

      h2 {
        line-height: 40px;
        font-size: $font-size-22;
      }

      .slider-video-button {
        left: 50%;
        position: absolute;
        width: 70%;
        bottom: 25%;

        button {
          width: 100%;
        }
      }
    }
  }
}

.shipyard-footer-active {
  .shipyard-sneak-wrapper,
  .shipyard-sneak-overlay {
    height: 72vh;
  }
}

.shipyard-footer-active {
  position: relative;
  background-color: $light;
}
