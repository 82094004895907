@import '../../styles/Helpers.scss';

.Page {
  overflow: hidden;

  .TopNavMenu {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  @include media-breakpoint-up-medium {
    height: 100vh;
  }
}
