@import '../../styles/Vars';

.hero-banner {
  background-color: $primary;
  color: $light;
  position: relative;
  margin-bottom: 80px;

  a,
  a:hover {
    color: $secondary;
  }

  &.single-banner {
    padding-top: 150px;
    padding-bottom: 150px;

    .banner-text {
      text-transform: none;
    }

    .banner-image {
      bottom: -190px;
    }
  }
}

.banner-text {
  padding: 40px 0 58px 40px;

  .title {
    font-size: $font-size-24;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 800;
    color: $light;

    > span {
      color: $secondary-light;
      display: block;
      font-size: $font-size-54;

      span {
        font-weight: 300;
      }
    }
  }

  .message {
    color: $secondary-light;
    padding-top: 28px;
    opacity: 0.5;
    font-size: $font-size-6;
    letter-spacing: 2px;
    font-weight: 600;
  }
}

.banner-image {
  bottom: -90px;
  position: absolute;
  right: 10px;
  width: 60%;
}
