// VARIABLES
//==========================

// Fonts
$pn-font-path: '../assets/fonts';
$font-family-sans-serif: 'Proxima Nova', sans-serif;
$font-family-serif: 'Roboto Slab', serif;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 3.75;

$font-size--1: ($font-size-base - 0.0625) !default;
$font-size--2: ($font-size-base - 0.125) !default;
$font-size--4: ($font-size-base - 0.25) !default;
$font-size--6: ($font-size-base - 0.375) !default;

$font-size-1: ($font-size-base + 0.0625) !default;
$font-size-2: ($font-size-base + 0.125) !default;
$font-size-3: ($font-size-base + 0.1875) !default;
$font-size-4: ($font-size-base + 0.25) !default;
$font-size-5: ($font-size-base + 0.3125) !default;
$font-size-6: ($font-size-base + 0.375) !default;
$font-size-7: ($font-size-base + 0.4375) !default;
$font-size-8: ($font-size-base + 0.5) !default;
$font-size-10: ($font-size-base + 0.625) !default;
$font-size-11: ($font-size-base + 0.6875) !default;
$font-size-12: ($font-size-base + 0.75) !default;
$font-size-13: ($font-size-base + 0.8125) !default;
$font-size-14: ($font-size-base + 0.875) !default;
$font-size-16: ($font-size-base + 1) !default;
$font-size-18: ($font-size-base + 1.125) !default;
$font-size-20: ($font-size-base + 1.25) !default;
$font-size-22: ($font-size-base + 1.375rem) !default;
$font-size-23: ($font-size-base + 1.4375) !default;
$font-size-24: ($font-size-base + 1.5) !default;
$font-size-26: ($font-size-base + 1.625) !default;
$font-size-28: ($font-size-base + 1.75) !default;
$font-size-30: ($font-size-base + 1.875) !default;
$font-size-32: ($font-size-base + 2) !default;
$font-size-34: ($font-size-base + 2.125) !default;
$font-size-44: ($font-size-base + 2.75) !default;
$font-size-46: ($font-size-base + 2.875) !default;
$font-size-54: ($font-size-base + 3.375) !default;
$font-size-68: ($font-size-base + 4.25) !default;

$font-weight-medium: 500;

// Colors
$primary: #14191d;
$secondary: #9cd4dc;
$secondary-light: #8fd7df;
$light: #ffffff;
$dark: #041d49;
$dark-alternate: #666666;
$body-color: $primary;
$gray-600: #666666 !default;
$gray-200: #f6f9fa !default;
$woodsmoke: #15191d;
$gray-chateau: #959eac;
$aqua-island: #9bd3db;
$shark: #262A2C;
$oslo-gray: #8c9293;
$alto: #d8d8d8;

// Nav Menu
$navbar-dark-color: $light;
$navbar-dark-hover-color: rgba($navbar-dark-color, 0.75);
$navbar-dark-active-color: rgba($navbar-dark-color, 1);

$navbar-light-color: $primary;
$navbar-light-hover-color: rgba($navbar-light-color, 0.75);
$navbar-light-active-color: rgba($navbar-light-color, 1);

$navbar-padding-y: 0;
$navbar-brand-padding-y: 0;

$transition-fade: opacity 0.3s linear !default;

//Links
$link-hover-decoration: none;
$link-hover-color: rgba($secondary-light, 0.4);

$text-indent: 1.4rem;

//Home Slider
$slider-height: 100vh;

$main-page-padding-x: 100px;

//HAMBURGER
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 15px !default;
$hamburger-layer-width: 54px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: $primary !default;
$hamburger-layer-border-radius: 0 !default;
$hamburger-hover-opacity: 0.5 !default;

$grid-gutter-width: 15px;
$border-radius: 0;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;

hr {
  border-top: 4px solid $secondary-light !important;
}
