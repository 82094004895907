@import '../../styles/Vars';
@import '../../styles/Helpers';

.AppNavMenu {
  position: fixed;
  width: 100vw;
  top: 0;
  right: -100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 108;
  transition: all 0.5s;
  display: none;
  
  &.is-active {
    right: 0;
    display: block;
    z-index: 110;
  }

  .MenuContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .MenuBody {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 140px 0 85px 0;
  }
  .MenuFooter {
    position: absolute;
    bottom: 0;
    width: 260px;
    height: 128px;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .MenuImage {
    display: block;
    height: 100%;
  }

  ul {
    li {
      .MenuText {
        font-weight: 300;
      }
      &:hover {
        .MenuText {
          font-weight: 600;
          color: $primary;
        }
      }
    }

    @include media-breakpoint-up-medium {
      padding-left: 50px;

      li {
        .MenuText {
          font-size: 36px;
          letter-spacing: 4px;
        }
      }
    }
  }
}
