@import 'Vars';
@import 'Helpers';

.static-page {
  margin-top: 150px;

  h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: $font-size-16;
    margin-bottom: 50px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-44;
      letter-spacing: 3px;
    }
  }

  p {
    color: $gray-600;
    font-size: $font-size-2;
    margin-bottom: 30px;
  }
  
  h3 {
    margin-bottom: 30px;
    color: $gray-600;
    font-size: $font-size-6;
  }
}
