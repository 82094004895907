@import '../../styles/Vars';
@import '../../styles/Helpers';

.HomeSlider {
  .SliderItem {
    height: $slider-height;
    overflow: hidden;

    .BackgroundImage {
      height: $slider-height;
      background-position: center center;
    }
  }

  .slick-vertical {
    .slick-slide {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
  }

  .slick-slider {
    height: 100vh;
    overflow-y: hidden;

    .slick-dots {
      display: flex !important;
      flex-direction: column;
      width: 30px;
      top: 0;
      bottom: 0;
      justify-content: center;
      margin-left: 5%;

      ul {
        padding-left: 0;

        li {
          display: block;
          width: 100%;
          height: 28px;

          &:last-child {
            display: none !important;
          }

          button {
            float: left;
          }

          span {
            float: left;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2px;
            display: none;
          }

          button::before {
            color: #d8d8d8;
            opacity: 1;
            font-size: 5px;
            line-height: 28px;
          }

          &:hover {
            button::before {
              color: #8fd7df;
              opacity: 1;
            }
          }

          &.slick-active {
            button::before {
              content: none;
            }

            span {
              display: block;
              position: absolute;
              left: 30px;
              top: 6px;
              width: 240px;
              text-align: left;
              cursor: default;
            }
          }
        }

        &.dark {
          span {
            color: $primary;
          }

          li.slick-active {
            background: url('./home-banner-selected-line-dark.svg') 8px center
              no-repeat;
          }
        }

        &.light {
          span {
            color: #9bd3db;
          }

          li.slick-active {
            background: url('./home-banner-selected-line.svg') 8px center
              no-repeat;
          }
        }
      }
    }
  }
}

.slider-video {
  height: 100%;
  width: 177.77777778vh;
  min-width: 100%;
  min-height: 56.25vw;

  @include media-breakpoint-up-huge {
    /* margin-top: -72px; */
  }

  @include media-breakpoint-16_10 {
    transform: translateX(-5%);
  }

  &.video-finished {
    display: none !important;
  }
}

.video-button-enabled {
  position: relative;
  height: 100%;
}

.slider-video-button {
  position: absolute;
  bottom: 10%;
  left: 20%;
  display: block;
  z-index: 2;

  button {
    background-color: transparent;
    padding: 6px 20px 4px;
    font-size: $font-size-2;
    letter-spacing: 5px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:active,
    &:focus {
      outline: none;
    }

    img {
      height: 12px;
    }

    span {
      padding-right: 50px;
    }

    &.alpha {
      border: 2px solid $aqua-island;
      color: $aqua-island;
    }

    &.beta {
      border: 2px solid $woodsmoke;
      color: $woodsmoke;
    }
  }

  @include media-breakpoint-down-medium {
    left: 50%;
    transform: translateX(-50%);
    bottom: 15%;
    width: 80%;

    button {
      font-size: $font-size--4;
      padding: 10px 20px 8px;
      letter-spacing: 3px;
      width: 100%;

      span {
        padding-right: 20px;
      }
    }
  }
}

.shipyard-sneak-active {
  display: none !important;
}

.disable-wheel {
  .HomeSlider {
    pointer-events: none;
  }
}

.slider-next-button-wrapper {
  position: absolute;
  bottom: 5%;
}

.slider-next-button {
  background: none;
  border: none;
  color: $secondary-light;
  text-transform: uppercase;
  font-size: $font-size-2;
  display: flex;
  align-items: center;
  line-height: 1;

  &:active,
  &:focus {
    outline: none;
  }

  img {
    padding-right: 10px;
    margin-top: -1px;
  }
}
