@import '../../styles/Vars';
@import '../../styles/Helpers';

// General Styles
.slick-arrow {
  height: auto !important;
  width: auto !important;
  z-index: 109;
  transform: none !important;

  img {
    height: 16px;
  }

  &:before {
    content: '' !important;
  }

  &.slick-disabled {
    opacity: 0.4;
  }
}

.slick-list {
  padding-left: 0 !important;
}

.nav-top {
  position: relative;

  .slick-slide {
    > div {
      margin-right: 15px;
    }

    .slider-item {
      position: relative;
    }
  }

  .slick-arrow {
    top: 0;
  }

  .slick-prev {
    left: auto;
  }

  .slider-title {
    font-size: $font-size-13;
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: uppercase;
  }
}

.nav-bottom {
  position: relative;

  .slick-slide {
    > div {
      margin-right: 20px;
    }
  }

  .slick-arrow {
    height: auto;
    bottom: 8px;
    top: auto;
  }

  .slick-prev {
    left: auto;
  }

  .slider-item {
    display: flex;
    position: relative;
    flex-direction: column;

    @include media-breakpoint-up-medium {
      flex-direction: column-reverse;
    }
  }

  .slider-title {
    padding-top: 10px;
    font-weight: 300;
    font-size: $font-size--2;
    font-weight: 700;
    letter-spacing: 1.3px;
    margin-bottom: 15px;

    @include media-breakpoint-up-medium {
      letter-spacing: 2px;
      font-weight: 300;
      font-size: $font-size-5;
    }
  }
}

.nav-bottom-right {
  .slider-item {
    position: relative;
  }

  .slider-title {
    font-size: $font-size-6;
    font-weight: 600;
    text-transform: uppercase;
  }

  .slick-prev,
  .slick-next {
    top: auto !important;
    bottom: 30px !important;
  }

  .slick-prev {
    left: auto;
    right: 5% !important;
  }

  .slick-next {
    right: 3% !important;
  }

  .slick-arrow img {
    height: 32px;
  }
}

.overflow-faded {
  .slick-slide {
    opacity: 0.4;
    transition: opacity 0.4s ease-in-out;

    > div {
      margin-right: 40px;
    }

    &.slick-active {
      opacity: 1;
    }
  }
}

.only-image-slider {
  .slick-slide {
    > div {
      margin-right: 15px;
    }
  }
}

.gallery-page {
  .slider-item {
    position: relative;
  }

  .slick-slide {
    > div {
      margin-right: 30px;
    }
  }

  .slick-arrow {
    bottom: 0;
    top: auto;
    bottom: -62px;
  }

  .slick-prev {
    left: auto;
    right: 160px !important;
    

    img {
      transform: rotate(180deg);
    }
  }

  .slick-next {
    right: 50px !important;
  }

  .img-zoom-button {
    bottom: 20px;
  }

}

.img-zoom-button {
  position: absolute;
  border: 2px solid $primary;
  right: 20px;
  bottom: 60px;
  height: 46px;
  width: 46px;
  background-color: rgba($light, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  &:active,
  &:focus {
    outline: none;
  }

  @include media-breakpoint-down-medium {
    width: 26px;
    height: 26px;
    right: 10px;
    bottom: 50px;

    img {
      width: 16px;
    }
  }
}

.slider-filter {
  button {
    background-color: transparent;
    border: none;
    margin: 0;
    text-transform: uppercase;
    font-size: $font-size-4;
    letter-spacing: 3px;
    padding: 0 5px;
    line-height: 1;
    position: relative;
    opacity: 0.2;
    transition: opacity 0.2s ease-in;

    &:active,
    &:focus {
      outline: none;
    }

    &.active-filter {
      opacity: 1;
    }
  }

  span {
    width: 1px;
    height: 10px;
    display: inline-block;
    background-color: $primary;
    margin: 0 20px;
  }

  &.filter-mobile {
    padding-right: 10px;
    padding-top: 12px;

    button {
      font-size: $font-size--2;
      letter-spacing: 2.3px;
    }

    span {
      margin: 0 10px;
    }
  }
}
