@mixin media-breakpoint-down-medium {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin video-desktop-fix {
  @media (min-width: 1119px) and (max-width: 1281px) {
    @content;
  }
}

@mixin media-breakpoint-up-medium {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin media-breakpoint-up-xxl {
  @media (min-width: 1281px) {
    @content;
  }
}

@mixin media-breakpoint-up-xxxl {
  @media (min-width: 1339px) {
    @content;
  }
}

@mixin media-breakpoint-up-huge {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin media-breakpoint-16_10 {
  @media (max-aspect-ratio: 16/10) {
    @content;
  }
}
