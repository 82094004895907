@import '../../styles/Vars';
@import '../../styles/Helpers';

.gallery-wrapper {
  background-color: $woodsmoke;
  display: flex;
  flex-direction: column-reverse;
  
  width: 100vw;
  justify-content: space-between;

  .gallery-types {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .gallery-type-selector {
      background: $shark;
      border: none;
      color: $light;
      font-size: $font-size-2;
      position: relative;
      text-transform: uppercase;
      width: 40vw;
      transition: all 0.3s ease;
      height: 54px;

      &:focus {
        outline: none;
      }

      span {
        display: block;
        line-height: 1;
        opacity: 0.3;
      }

      &.active-type {
        background-color: $light;
        width: 60vw;
        height: 65px;

        span {
          color: $woodsmoke;
          opacity: 1;
        }
      }
    }
  }

  .gallery-inner {
    color: $aqua-island;
    width: 100vw;
    background-color: $woodsmoke;
    

    .gallery-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 40px 25px;

      .gallery-title {
        text-transform: uppercase;
        font-size: $font-size-8;
        font-weight: 600;
        line-height: 1;

        span {
          font-weight: 300;
        }
      }
    }

    .gallery-body {
      padding-left: 25px;

      .count-label {
        padding-right: 100px;
        padding-top: 40px;
      }
    }
  }

  .slider-filter {
    margin-top: -27px;

    button {
      color: $light;

      &.active-filter {
        color: $aqua-island;
      }
    }

    span {
      background-color: $light;
      opacity: 0.2;
    }

    &.filter-mobile {
      margin-top: 0;
      padding-right: 20px;
      padding-top: 40px;
    }
  }

  .img-zoom-button {
    bottom: 10px;
  }

  .slick-slide > div {
    margin-right: 25px;
  }

  .slider-image {
    background-position: center;
    background-size: cover;
    
    background-repeat: no-repeat;
  }

  @include media-breakpoint-up-medium {
    height: 100vh;
    flex-direction: row;
    justify-content: flex-start;

    .gallery-title {
      font-size: $font-size-34;
    }

    .gallery-header {
      padding: 40px 100px;
    }

    .gallery-body {
      padding-left: 100px;
    }

    .gallery-inner {
      width: calc(100vw - 120px);
      height: auto;
    }

    .gallery-types {
      width: 120px;
      flex-direction: column;
      align-items: flex-start;

      .gallery-type-selector {
        height: 40%;
        width: 100px;
        font-size: $font-size-14;

        span {
          transform: translateY(-50%) rotate(-90deg);
          position: absolute;
          left: -19px;
        }

        &.active-type {
          width: 120px;
          height: 60%;
        }
      }
    }

    .slider-image {
      height: calc(100vh - 270px);
    }

    .img-zoom-button {
      bottom: 20px;
    }

    .count-label {
      font-size: $font-size-4;
      font-weight: 300;
      display: flex;
      justify-content: flex-end;

      .separator,
      .total {
        color: $oslo-gray;
      }

      .separator {
        padding: 0 5px;
      }
    }
  }
}
