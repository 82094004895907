@import '../../styles/Vars';
@import '../../styles/Helpers';

.new-build-wrapper {
  margin-top: 200px;

  .banner-image {
    width: 60%;
  }

  @include media-breakpoint-down-medium {
    &.container-fluid {
      padding-left: 0;
      padding-right: 0;
      margin-top: 100px;
    }
  }
}

.new-build-slider {
  padding-left: 10%;
  margin-top: 120px;
}

.title {
  &-new-build,
  &-fleet {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-size: $font-size-14;
    margin-bottom: 30px;

    @include media-breakpoint-up-medium {
      letter-spacing: 3px;
      font-size: $font-size-54;
    }
  }
}

.text {
  &-new-build,
  &-fleet {
    font-weight: 300;
    font-size: $font-size-8;
    line-height: 20px;
    margin-bottom: 40px;

    span {
      font-weight: 800;
    }

    &--small {
      font-weight: 300;
      padding-top: 15px;
    }

    @include media-breakpoint-up-medium {
      font-size: $font-size-32;
      line-height: 62px;
      margin-bottom: 0;

      &--small {
        font-size: $font-size-12;
        line-height: 38px;
      }
    }
  }
}

.hero-banners {
  margin-top: 85px;
  margin-bottom: 60px;
}

.hero-banner-mobile {
  .image-wrapper {
    position: relative;
    text-align: center;

    img {
      z-index: 2;
      width: 80%;
      position: relative;
    }
  }

  .image-wrapper-background {
    background-color: $primary;
    position: absolute;
    top: 11%;
    bottom: 11%;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .hero-banner-text {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    align-items: center;

    > span {
      font-size: $font-size-2;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      font-weight: 600;

      span {
        font-weight: 300;
      }
    }

    button {
      font-size: $font-size--2;
      text-transform: uppercase;
      letter-spacing: 2px;
      border: none;
      background-color: transparent;

      img {
        vertical-align: baseline;
        margin-left: 5px;
      }
    }
  }
}
