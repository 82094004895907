@import '~bootstrap/scss/bootstrap';
@import '../../styles/Vars';
@import '../../styles/Helpers';

.footer {
  padding-bottom: 30px;
  padding-top: 70px;

  @include media-breakpoint-up-medium {
    padding-top: 78px;
    padding-bottom: 78px;
  }
}

.social-share {
  a:not(:last-child) {
    padding-right: 10px;
  }

  @include media-breakpoint-down-medium {
    margin-top: 50px;
  }
}

.footer-info {
  color: $gray-600;
  font-size: $font-size--4;

  p {
    margin: 12px 0;
  }

  @include media-breakpoint-down-medium {
    margin-bottom: 40px;
  }
}

.footer-logo {
  max-width: 180px;
}

.footer-menu {
  li {
    a {
      color: $dark-alternate;
      display: block;
      font-weight: 300;
      text-transform: uppercase;
      font-size: $font-size--4;

      @include media-breakpoint-up-medium  {
        font-size: $font-size-base;
        letter-spacing: 3px;
      }
    }

    &:not(:last-child) {
      a {
        padding-bottom: 12px;
      }
    }
  }
}
