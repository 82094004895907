@font-face {
    font-family: 'Proxima Nova';
    src: url('#{$pn-font-path}/proxima_nova_light-webfont.eot');
    src: url('#{$pn-font-path}/proxima_nova_light-webfont.woff2') format('woff2'),
         url('#{$pn-font-path}/proxima_nova_light-webfont.woff') format('woff'),
         url('#{$pn-font-path}/proxima_nova_light-webfont.ttf') format('truetype'),
         url('#{$pn-font-path}/proxima_nova_light-webfont.svg#proxima_novaregular') format('svg');
    font-weight: 300;
    font-style: light;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('#{$pn-font-path}/proxima_nova-webfont.eot');
    src: url('#{$pn-font-path}/proxima_nova-webfont.woff2') format('woff2'),
         url('#{$pn-font-path}/proxima_nova-webfont.woff') format('woff'),
         url('#{$pn-font-path}/proxima_nova-webfont.ttf') format('truetype'),
         url('#{$pn-font-path}/proxima_nova-webfont.svg#proxima_novaregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('#{$pn-font-path}/proxima_nova_semibold-webfont.eot');
    src: url('#{$pn-font-path}/proxima_nova_semibold-webfont.woff2') format('woff2'),
         url('#{$pn-font-path}/proxima_nova_semibold-webfont.woff') format('woff'),
         url('#{$pn-font-path}/proxima_nova_semibold-webfont.ttf') format('truetype'),
         url('#{$pn-font-path}/proxima_nova_semibold-webfont.svg#proxima_novaregular') format('svg');
    font-weight: 600;
    font-style: bold;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('#{$pn-font-path}/proxima_nova_light-webfont.eot');
    src: url('#{$pn-font-path}/proxima_nova_light-webfont.woff2') format('woff2'),
         url('#{$pn-font-path}/proxima_nova_light-webfont.woff') format('woff'),
         url('#{$pn-font-path}/proxima_nova_light-webfont.ttf') format('truetype'),
         url('#{$pn-font-path}/proxima_nova_light-webfont.svg#proxima_novaregular') format('svg');
    font-weight: 300;
    font-style: bold;
}