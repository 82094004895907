// VARIABLES
//==========================
@import 'Vars';
@import 'Helpers';

// FONTS
//==========================
@import 'Fonts';
@import 'PageComponents';

// BOOTSTRAP
//==========================
@import '~bootstrap/scss/bootstrap';

@import '~hamburgers/_sass/hamburgers/hamburgers';

// App CSS
//==========================
body,
html {
  overflow-x: hidden;
  min-width: 320px;
  /* min-height: 100vh; */
  height: 100%;
  overscroll-behavior: none;
  position: relative;

  .menu-active {
    overflow: hidden;
  }
}

header {
  position: relative;
}

*:focus {
  outline: none;
}

.fade-transition {
  opacity: 0.01;
}

.fade-transition-enter.fade-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-transition-leave {
  opacity: 1;
}

.fade-transition-leave.fade-transition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
