@import '../../styles/Vars';
@import '../../styles/Helpers';

.TopNavMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 111;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  background-color: $light;
  transition: all 0.5s;

  .Logo {
    padding-left: 5%;

    img {
      height: 24px;
    }

    button {
      background: transparent;
      border: none;
    }
  }

  .RightMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 5%;

    .MenuButton {
      display: flex;
      flex-direction: row;

      .CloseButton {
        display: none;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        background: none;
        border: none;
        opacity: 1;
        padding-bottom: 6px;
        &:focus {
          outline: none;
        }
        &:hover {
          opacity: 0.5;
        }
        /* &.is-active {
          display: block;
        } */
      }
    }

    .LanguageMenu {
      display: none !important;
      padding-bottom: 5px;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      display: none;
      &:focus {
        outline: none;
      }
      &.is-active {
        display: block;
      }

      .LanguageButton {
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        background: none;
        border: none;
        opacity: 0.5;

        &:focus {
          outline: none;
        }
        &.is-active {
          opacity: 1;
        }
      }
    }

    .hamburger:focus {
      outline: none;
    }
  }

  @include media-breakpoint-up-medium {
    padding-top: 30px;
    padding-bottom: 30px;

    &:not(.onTop) {
      box-shadow: 0 0 20px rgba(0,0,0,.1);
    }

    .Logo img {
      height: 41px;
    }
  }

  &.onTop {
    background-color: transparent;
  }

  &.hidden {
    transition: opacity 1s;
    opacity: 0;
  }

  &.active {
    transition: opacity 1s;
    opacity: 1;
  }

  @include media-breakpoint-down-medium {
    &.onTop {
      background-color: rgba(255, 255, 255, 0.5);
      padding-top: 0;
    }

    &.onTop.secondary {
      background-color: rgba(0, 0, 0, 0.5);
      padding-top: 0;
    }
  }
}

.hamburger-text {
  font-weight: 300;
  line-height: 1;
  display: block;
  margin-top: -6px;
  padding-left: 53px;
  display: block;

  &.is-active {
    display: none;
  }
}

.hamburger-inner {
  bottom: 4px !important;
}

.hamburger--collapse .hamburger-inner::after {
  display: none;
}

.secondary {
  .LanguageButton,
  .LanguageButtonseparator,
  .hamburger-text {
    color: $secondary;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: $secondary;
  }
}
