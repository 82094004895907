@import '../../styles/Vars';
@import '../../styles/Helpers';

.beach-club-wrapper {
  margin-top: 100px;
  padding-left: 25%;

  h2 {
    text-transform: uppercase;
    font-size: $font-size-24;
    font-weight: 800;
    letter-spacing: 4px;
    margin-bottom: 25px;
  }

  p {
    color: $dark-alternate;
    font-size: $font-size-4;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 34px;
  }

  .layout-image {
    margin-top: -34px;
    max-height: 45vh;

    @include media-breakpoint-up-xxl {
      max-height: 55vh;
    }
  }

  .layout-note {
    font-weight: 600;
    font-size: $font-size-14;
    color: $secondary;
    text-align: center;
  }

  @include media-breakpoint-down-medium {
    padding-left: 20%;
    padding-right: 20%;
    margin-top: 100px;

    h2 {
      font-size: $font-size-14;
      text-transform: none;
      letter-spacing: inherit;
    }

    p {
      font-size: $font-size--2;
      line-height: 20px;
      margin-bottom: 25px;
    }

    .layout-note {
      font-size: $font-size--2;
    }

    .layout-image {
      margin-top: -20px;
    }
  }
}

.beach-club-video-wrapper {
  .slider-video {
    @include video-desktop-fix {
      transform: translateY(-20px);
    }
  }
}

.beach-content-active {
  .slider-video {
    display: none;
  }
}
