@import '../../styles/Vars';
@import '../../styles/Helpers';

.shipyard-hero {
  background: gray;
  padding: 110px 10% 0 10%;
  position: relative;

  h2 {
    color: $light;
    font-size: $font-size-14;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 40px;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
    letter-spacing: 2.5px;
  }

  p {
    color: $light;
    font-size: $font-size-2;
    font-weight: 300;
    line-height: 26px;
    position: relative;
    z-index: 3;
  }

  @include media-breakpoint-down-medium {
    .has-background-image .image-overlay {
      background-image: none;
      background-color: rgba(0, 0, 0, 0.7);
      display: block;
    }
  }

  @include media-breakpoint-up-medium {
    height: 100vh;

    h2 {
      font-size: $font-size-54;
    }

    p {
      max-width: 50%;
      font-size: $font-size-4;
    }
  }
}

.shipyard-media-items {
  p {
    font-weight: 300;
    font-size: $font-size-2;
    line-height: 28px;
    margin-top: 27px;
  }

  > .row:first-child {
    margin-bottom: 80px;
  }

  @include media-breakpoint-up-medium {
    p {
      font-size: $font-size-12;
      line-height: 38px;
    }

    .media-item-text-right {
      padding-left: 40px;
      margin-top: 40px;
    }

    .media-item-text-left {
      padding-right: 40px;
      margin-top: 196px;
    }

    > .row:first-child {
      margin-bottom: 0;
    }

    > .row:last-child {
      margin-top: -100px;
    }
  }
}

.shipyard-gallery {
  display: flex;
  flex-wrap: wrap;

  img {
    max-width: 25%;
  }
}

.shipyard-gallery-mobile {
  padding-bottom: 60px;

  .left-column {
    padding-right: 3px;
  }

  .right-column {
    padding-left: 3px;
  }

  .image-wrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    margin-bottom: 6px;
  }
}

.classification,
.research {
  h2 {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2.4px;
    font-size: $font-size-4;
    margin-bottom: 25px;
  }

  p {
    font-weight: 300;
    line-height: 24px;
  }

  @include media-breakpoint-up-medium {
    h2 {
      font-size: $font-size-24;
      letter-spacing: 4.8px;
    }

    p {
      max-width: 90%;
      font-size: $font-size-4;
      line-height: 28px;
    }
  }
}

.classification {
  padding-top: 70px;
  padding-bottom: 42px;

  .logos {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      height: 103px;
      max-width: 40%;

      &:first-child {
        padding-right: 50px;
      }
    }
  }

  @include media-breakpoint-up-medium {
    justify-content: space-around;
    padding-top: 130px;
    padding-bottom: 134px;

    img {
      max-width: 50%;

      &:first-child {
        height: 140px;
        padding-right: 0;
      }
    }
  }
}

.research .logos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;

  img {
    height: 70px;
  }

  @include media-breakpoint-up-medium {
    justify-content: flex-end;
    margin-top: 0;

    img {
      height: 126px;
    }
  }
}

.service-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 45px;
  position: relative;
  margin-bottom: #{$grid-gutter-width};

  p {
    position: relative;
    z-index: 3;
    line-height: 1;
    margin: 0;
  }

  .service-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($primary, 0.6);
  }

  @include media-breakpoint-up-medium {
    font-size: $font-size-4;
    height: 205px;

    p {
      line-height: 26px;
    }
  }
}

.title {
  &-shipyard {
    letter-spacing: 2.5px;
    text-transform: uppercase;
    font-size: $font-size-14;
    font-weight: 600;
    margin-bottom: 40px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-34;
      letter-spacing: 5px;
      margin-bottom: 80px;
    }
  }

  &-service {
    margin-bottom: 50px;
    font-weight: 800;
    text-transform: uppercase;
    font-size: $font-size-14;
    letter-spacing: 2.8px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-24;
      letter-spacing: 5px;
    }
  }
}
