@import '../styles/Helpers.scss';

.has-dark-background {
  background-color: $primary;
  color: $light;
}

.has-gray-background {
  background-color: $gray-200;
}

.has-background-image {
  background-position: center;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;

  @include media-breakpoint-up-medium {
    height: 100vh;
  }
}
