@import '../../styles/Vars';
@import '../../styles/Helpers';

.naval-sneak-wrapper {
  padding-top: 150px;
  padding-left: 25%;
  background-size: 0;

  h2 {
    font-weight: 800;
    font-size: $font-size-24;
    letter-spacing: 6px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  p {
    color: $dark-alternate;
    font-size: $font-size-4;
    width: 50%;
    line-height: 28px;
    font-weight: 300;
  }

  .sketch {
    margin-top: -100px;
  }

  @include media-breakpoint-down-medium {
    padding-left: 20%;
    padding-right: 20%;
    height: 100vh;
    background-size: 125%;
    background-position: -29vw 46vh;
    background-repeat: no-repeat;

    h2 {
      font-size: $font-size-14;
      letter-spacing: 3px;
    }

    p {
      width: 100%;
      line-height: 24px;
      font-size: $font-size-base;
    }
  }
}
