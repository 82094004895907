@import '../../styles/Vars';
@import '../../styles/Helpers';

.three-sixty-wrapper {
  background-size: cover;
  background-position: center;
  width: 100%;

  .three-sixty-inner {
    position: relative;
    padding-left: 25%;
    color: $light;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10%;
    pointer-events: none;

    > div {
      z-index: 12;
    }

    .ts-icon {
      margin-bottom: 30px;

      img {
        height: 60px;
      }

      span {
        font-weight: 300;
        font-size: $font-size-44;
        padding-left: 15px;
      }
    }

    h2 {
      font-size: $font-size-34;
      letter-spacing: 4px;
      text-transform: uppercase;
      font-weight: 800;
      margin: 0;
    }

    p {
      font-size: $font-size-14;
    }

    @include media-breakpoint-down-medium {
      padding: 0 0 0 10%;
      align-items: center;

      .ts-icon {
        margin-bottom: 10px;

        img {
          height: 40px;
        }

        span {
          font-size: $font-size-34;
        }
      }

      h2 {
        font-size: $font-size-14;
        text-transform: none;
        letter-spacing: inherit;
      }

      p {
        font-size: $font-size--2;
      }
    }

    @include media-breakpoint-up-medium {
      height: 100vh;
    }
  }

  .slider-video-button {
    left: auto;
    right: 10%;
    z-index: 13;

    .alpha {
      background-color: $aqua-island;
      color: $primary;
    }

    @include media-breakpoint-down-medium {
      left: 50%;
      right: auto;
    }
  }
}

.three-sixty-wrapper-mobile {
  padding: 7% 7% 25%;
  position: relative;
  z-index: 2;

  .mobile-360-image {
    width: 28%;
  }

  h2 {
    color: $light;
    font-weight: 600;
    font-size: $font-size-34;
    margin: 8px 0;
    line-height: 1;
  }

  p {
    margin-bottom: 40px;
    line-height: 1;
    color: $light;
    font-size: $font-size-2;
  }

  .btn-video {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    border: none;
    padding: 20px 20px 18px;

    img {
      height: 12px;
    }

    &.btn-interior-video {
      background-color: $primary;
      color: $light;
    }

    &.btn-exterior-video {
      background-color: $aqua-island;
    }
  }
}

.three-sixty-wrapper-mobile-overlay {
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: linear-gradient(
    230deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.3) 80%
  );
}

.mobile-360-video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 113;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;

  .close-mobile-video {
    position: absolute;
    top: 30px;
    right: 30px;
    background: transparent;
    border: none;
  }
}
