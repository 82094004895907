@import '../../styles/Vars';
@import '../../styles/Helpers';

.About {
  width: 100%;

  .HeaderContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 30px 5%;
    height: 100vh;

    @include media-breakpoint-down-medium {
      padding-left: 10%;
      padding-right: 10%;
    }

    .Title {
      color: #fff;
      font-weight: 600;
      font-size: $font-size-14;
      letter-spacing: 1.3px;
      z-index: 9;
      position: relative;
      line-height: 1;

      @include media-breakpoint-up-medium {
        font-size: $font-size-34;
        letter-spacing: 2.1px;
        margin-bottom: 20px;
      }

      @include media-breakpoint-up-xxxl {
        font-size: $font-size-44;
        letter-spacing: 2.6px;
        margin-bottom: 40px;
      }

      @include media-breakpoint-up-huge {
        font-size: $font-size-54;
        letter-spacing: 3px;
        margin-bottom: 50px;
      }
    }

    .Content {
      z-index: 9;
      position: relative;
      color: #fff;
      font-weight: 300;
      font-style: normal;

      @include media-breakpoint-up-medium {
        width: 65%;
      }

      @include media-breakpoint-up-xxxl {
        font-size: $font-size-2;
        line-height: 24px;
        width: 50%;
      }

      @include media-breakpoint-up-huge {
        font-size: $font-size-4;
        line-height: 24px;
      }
    }

    .HeaderImage {
      background: url('/images/about/about-bg.jpg') center center;
      background-size: cover;
      width: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;

      @include media-breakpoint-down-medium {
      }

      .Mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: contain;
        background-blend-mode: multiply;
        background-image: linear-gradient(
          to left,
          rgba(45, 54, 62, 0),
          #14191d
        );
      }
    }
  }
}

.has-drop-shadow {
  position: relative;
  margin-bottom: 100px;

  @include media-breakpoint-up-medium {
    margin-bottom: 0;
  }

  img {
    position: relative;
    z-index: 2;
  }

  .shadow {
    position: absolute;
    background-color: $primary;
    top: 15%;
    right: 10%;
    width: 140%;
    height: 100%;
  }

  &.right {
    .shadow {
      left: 100px;
    }
  }
}

.about-item {
  h3 {
    font-size: $font-size-16;
    letter-spacing: 2.3px;
    text-transform: uppercase;
    margin: 0;
    font-weight: 500;

    @include media-breakpoint-up-medium {
      font-size: $font-size-24;
      letter-spacing: 4px;
    }

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-34;
      letter-spacing: 5px;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-54;
      letter-spacing: 7px;
    }
  }

  h4 {
    color: $secondary;
    letter-spacing: 1.6px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-6;
      letter-spacing: 2.2px;
    }

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-10;
      letter-spacing: 2.6px;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-14;
      letter-spacing: 3px;
    }
  }

  p {
    font-size: $font-size--2;
    font-weight: 300;
    margin-top: 20px;
    line-height: 20px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-2;
      line-height: 26px;
    }

    @include media-breakpoint-up-xxxl {
      margin-top: 30px;
      font-size: $font-size-4;
    }

    @include media-breakpoint-up-huge {
      line-height: 28px;
    }
  }

  .item-media {
    cursor: pointer;

    &:hover {
      .image-overlay {
        opacity: 0;
      }
    }
  }

  .item-text-wrapper {
    justify-content: flex-start;
    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 40px;

    @include media-breakpoint-up-medium {
      justify-content: center;
    }
  }

  .item-text {
    /*  padding-top: 130px;
    padding: 130px 20px 40px 20px;

    @include media-breakpoint-up-medium {
      padding: 100px 30px 0 90px;
      width: 45%;
    }

    @include media-breakpoint-up-xxxl {
      padding: 100px 30px 0 100px;
    }

     */

    @include media-breakpoint-up-huge {
      max-width: 70%;
    }

    h4 {
      font-size: $font-size--2;

      @include media-breakpoint-up-medium {
        font-size: $font-size-6;
        letter-spacing: 2.2px;
      }

      @include media-breakpoint-up-xxxl {
        font-size: $font-size-10;
        letter-spacing: 2.6px;
      }

      @include media-breakpoint-up-huge {
        font-size: $font-size-14;
        letter-spacing: 3px;
      }
    }
  }

  @include media-breakpoint-up-medium {
  }

  @mixin media-breakpoint-up-xxl {
  }

  @include media-breakpoint-up-huge {
  }
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(to left, rgba(45, 54, 62, 0), #14191d);
  z-index: 2;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  pointer-events: none;
  display: none;

  @include media-breakpoint-up-medium {
    display: block;
  }
}

.interview-video {
  video {
    width: 100%;
    height: auto;
  }
}

.about-slider-thumbs {
  .slick-list {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  .slick-track {
    background-color: $primary;
    display: flex;
  }

  .slick-slide {
    > div {
      margin: 5px;

      @include media-breakpoint-up-medium {
        margin: 12px;
      }

      @include media-breakpoint-up-xxxl {
        margin: 15px;
      }

      @include media-breakpoint-up-huge {
        margin: 20px;
      }
    }

    .thumb-item {
      transition: all 0.3s ease;
    }

    &.slick-current {
    }

    &:not(.slick-current) {
      > div {
        .has-background-image {
          opacity: 0.6;
        }
      }
    }
  }
}

.thumb-items {
  padding-top: 20px;
  padding-bottom: 50px;

  @include media-breakpoint-up-medium {
    margin: 0 100px;
  }
}

.thumb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba($primary, 0.4);
  z-index: 1;
}

.thumb-items-wrapper {
  @include media-breakpoint-down-medium {
    width: 130% !important;
    max-width: 130% !important;
    overflow: auto;

    .thumb-items {
      width: 130%;
      margin-left: -12%;
      padding-bottom: 70px;
    }
  }
}

.thumb-item {
  position: relative;
  padding-left: 5px;
  padding-bottom: 5px;
  height: 65px;
  cursor: pointer;
  width: 120px;

  .has-background-image {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-up-medium {
    padding-left: 12px;
    padding-bottom: 12px;
    height: 158px;
    width: calc(25% - 20px);
    padding-left: 10px;
    padding-bottom: 10px;
  }

  @include media-breakpoint-up-xxxl {
    padding-left: 16px;
    padding-bottom: 14px;
    height: 181px;
  }

  @include media-breakpoint-up-huge {
    padding-left: 22px;
    padding-bottom: 25px;
    height: 232px;
  }

  h3,
  p {
    position: relative;
    z-index: 2;
    margin: 0;
    color: $light;
    text-align: left;
  }

  h3 {
    font-size: $font-size--6;
    font-weight: 700;
    letter-spacing: 0.8px;
    text-transform: uppercase;

    @include media-breakpoint-up-medium {
      font-size: $font-size-4;
      letter-spacing: 0;
    }

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-6;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-18;
    }
  }

  p {
    display: none;

    @include media-breakpoint-up-medium {
      display: block;
      font-size: $font-size--2;
      letter-spacing: 1.4px;
      line-height: 1;
    }
  }

  &.active-thumb {
    .thumb-overlay {
      background: rgba($primary, 0.8);
    }
  }
}

.video-play-icon {
  top: 50%;
  background-color: transparent;
  border: none;
  left: 50%;
  position: absolute;
  z-index: 3;
  transform: translateX(-50%) translateY(-50%);
  opacity: 1;
  transition: all 0.4s ease-in-out;

  img {
    width: 50px;

    @include media-breakpoint-up-medium {
      width: 82px;
    }
    @include media-breakpoint-up-xxxl {
      width: 93px;
    }
  }
}

.excavations-wrapper {
  padding-top: 135px;

  @include media-breakpoint-up-medium {
    padding-top: 90px;
  }

  @include media-breakpoint-up-xxxl {
    padding-top: 105px;
  }

  @include media-breakpoint-up-huge {
    padding-top: 160px;
  }
}

.ancient-gallery-wrapper {
  display: flex;
  padding-left: 10%;
  padding-top: 25px;
  padding-bottom: 122px;

  > div:not(:last-child) {
    margin-right: 7px;

    @include media-breakpoint-up-medium {
      margin-right: 20px;
    }
  }

  .ancient-vertical,
  .ancient-horizontal {
    background-size: cover;
    background-position: center;
    height: 150px;

    @include media-breakpoint-up-medium {
      height: 480px;
    }
  }

  .ancient-vertical {
    width: 30%;

    @include media-breakpoint-up-medium {
      width: 20%;
    }
  }

  .ancient-horizontal {
    width: calc(70% - 14px);

    @include media-breakpoint-up-medium {
      width: 40%;
    }
  }

  @include media-breakpoint-up-medium {
    padding-top: 55px;
    padding-bottom: 70px;
  }

  @include media-breakpoint-up-xxxl {
    padding-bottom: 96px;
  }

  @include media-breakpoint-up-huge {
    padding-top: 100px;
    padding-bottom: 176px;
  }
}

.img-fluid.about-row-image {
  @include media-breakpoint-up-medium {
  }

  @include media-breakpoint-up-xxl {
  }

  @include media-breakpoint-up-huge {
  }
}

.header-philosophy {
  margin-top: 90px;
  margin-bottom: 60px;

  @include media-breakpoint-up-medium {
    margin-top: 140px;
    margin-bottom: 120px;
  }

  @include media-breakpoint-up-xxxl {
    margin-top: 170px;
    margin-bottom: 160px;
  }

  @include media-breakpoint-up-huge {
    margin-top: 210px;
    margin-bottom: 190px;
  }
}

.about-row-wrapper {
  margin-bottom: 50px;

  @include media-breakpoint-down-medium {
    &.image-left {
      padding-right: 0;

      .title-about-image,
      .text-about-image {
        padding-right: 30px;
      }
    }

    &.image-right {
      padding-left: 0;

      .title-about-image,
      .text-about-image {
        padding-left: 30px;
      }
    }
  }

  @include media-breakpoint-up-medium {
    margin-bottom: 200px;
  }
}

.title {
  &-philosophy {
    font-size: $font-size-14;
    margin-bottom: 30px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-34;
      letter-spacing: 5px;
      margin-bottom: 0;
    }

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-44;
      letter-spacing: 6px;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-54;
      letter-spacing: 7px;
    }
  }

  &-about-image {
    font-size: $font-size-8;
    margin-bottom: 30px;
    letter-spacing: 2px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-10;
      letter-spacing: 2.2px;
    }

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-14;
      letter-spacing: 2.5px;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-18;
      letter-spacing: 2.7px;
    }
  }

  &-excavations {
    font-size: $font-size-14;
    line-height: 36px;
    letter-spacing: 3.6px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 30px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-26;
      letter-spacing: 5px;
      line-height: 52px;
      margin-bottom: 0;
    }

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-30;
      letter-spacing: 5.5px;
      line-height: 56px;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-34;
      letter-spacing: 6px;
      line-height: 56px;
    }
  }
}

.text {
  &-philosophy {
    font-size: $font-size-8;
    line-height: 34px;

    @include media-breakpoint-up-medium {
      font-size: $font-size-16;
      line-height: 46px;
    }

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-20;
      line-height: 50px;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-28;
      line-height: 50px;
    }
  }

  &-about-image {
    font-size: $font-size-2;
    line-height: 28px;
    font-weight: 300;

    @include media-breakpoint-up-medium {
      font-size: $font-size-6;
      line-height: 34px;
    }

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-10;
      line-height: 38px;
    }

    @include media-breakpoint-up-huge {
      font-size: $font-size-14;
      line-height: 38px;
    }
  }

  &-excavations {
    font-weight: 300;
    font-size: $font-size-2;
    line-height: 28px;

    @include media-breakpoint-up-xxxl {
      font-size: $font-size-4;
    }
  }
}

.about-image-title {
  font-size: $font-size-6;
  letter-spacing: 2px;

  @include media-breakpoint-up-medium {
    font-size: $font-size-10;
    letter-spacing: 2.2px;
  }

  @include media-breakpoint-up-xxxl {
    font-size: $font-size-14;
    letter-spacing: 2.5px;
  }

  @include media-breakpoint-up-huge {
    font-size: $font-size-18;
    letter-spacing: 2.9px;
  }
}

.about-image-text {
  font-size: $font-size-14;
  line-height: 38px;
  padding-top: 16px;
}

.about-interview {
  margin-top: 200px;
}

.about-slider-last {
  margin-top: 90px;

  .image-only-gallery {
    .image-only-item {
      @include media-breakpoint-up-medium {
        padding: 0 10px;
      }
    }
  }
}

.about-slider-last-mobile {
  padding-bottom: 50px;

  .left-column {
    padding-right: 3px;
  }

  .right-column {
    padding-left: 3px;
  }

  .image-wrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    margin-bottom: 6px;
  }
}
