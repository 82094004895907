@import '../../styles/Vars';
@import '../../styles/Helpers';

.technical-wrapper {
  background-color: $woodsmoke;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 120;

  @include media-breakpoint-down-medium {
    flex-direction: column-reverse;
  }
}

.specs-trigger {
  align-items: center;
  background-color: $light;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 70px 0;
  transition: width 0.4s;
  width: 80px;
  position: relative;

  &.active {
    width: 40%;
    align-items: flex-start;
    padding: 50px;
    max-height: 100vh;
    overflow: auto;

    .trigger-label {
      display: none;
    }

    .trigger-button {
      position: fixed;
      left: -85px;
      bottom: 70px;

      img {
        transform: rotate(180deg);
      }
    }

    .spec-table {
      display: block;
    }
  }

  .trigger-button {
    button {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      white-space: nowrap;
      display: block;
      transform: rotate(-90deg);
      padding-left: 100px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
    }
  }

  @include media-breakpoint-down-medium {
    width: 100%;
    flex-direction: row;
    padding: 14px;

    .trigger-label {
      transform: none;
      font-size: $font-size-2;
    }

    .trigger-button {
      button {
        flex-direction: row;
      }
      img {
        width: 8px;
        margin-left: 10px;
      }

      span {
        transform: none;
        padding: 0;
        font-size: $font-size--2;
      }
    }

    &.active {
      width: 100%;
      top: 0;
      position: absolute;
      z-index: 111;
      padding: 5%;

      .trigger-button {
        top:14px;
        right: 20px;
        left: auto;
        bottom: auto;
        padding: 5px;
        z-index: 111;

        span {
          display: none;
        }

        img {
          margin: 0;
          width: 12px;
        }
      }

      h3 {
        font-size: $font-size-4;
        letter-spacing: 1px;
      }

      .list-header {
        font-size: $font-size-2;
      }
    }
  }
}

.trigger-label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: $font-size-13;
  transform: rotate(-90deg) translateX(-50%);
}

.specs-main {
  color: $light;
  width: 100%;

  &.shrinked {
    width: 60%;

    .btn-icon-only {
      display: none;
    }
  }
}

.main-header {
  padding: 80px 6% 0 6%;
  text-transform: uppercase;
  font-size: $font-size-24;
  line-height: 1;
  font-weight: 600;

  span {
    color: $secondary;

    span {
      font-weight: 300;
    }
  }

  @include media-breakpoint-down-medium {
    padding-top: 10px;
    font-size: $font-size-8;
    letter-spacing: 2px;

    span {
      font-size: $font-size-12;
    }

    button {
      position: absolute;
      top: 26px;
      right: 5%;
      z-index: 111;
    }
  }
}

.main-footer {
  padding: 0 90px 20px 90px;
}

.spec-table {
  display: none;

  h3 {
    font-size: $font-size-20;
    font-weight: 600;
    letter-spacing: 0.15rem;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  .list-header {
    color: $secondary;
  }

  .list-item {
    color: $gray-chateau;
  }
}

.tech-slider-wrapper {
  height: 100%;
  padding-top: 4%;

  @include media-breakpoint-down-medium {
    padding: 0 5%;
  }
}

.tech-slider {
  width: calc(100vw - 75px);
  transition: width 0.2s;

  .main-image {
    position: relative;
    padding-bottom: 25vh;

    .img-label {
      bottom: -10%;
      font-size: $font-size-14;
      font-weight: 300;
      padding-right: 6%;
      text-transform: uppercase;
      text-align: right;
      letter-spacing: 6px;

      @include media-breakpoint-down-medium {
        text-align: left;
        padding: 0;
        max-width: 40vw;
      }
    }
  }

  .slick-arrow {
    bottom: 30px;
    top: auto;
    right: 6%;
    left: auto;

    img {
      height: 32px;
    }

    @include media-breakpoint-down-medium {
      right: auto;
      left: 5%;

      img {
        height: 23px;
      }

      &.slick-next {
        left: calc(5% + 60px);
      }
    }
  }

  .slick-prev {
    right: calc(6% + 100px);

    img {
      transform: rotate(180deg);
    }
  }

  &.specs-open {
    width: 60vw;

    .main-footer {
      margin-top: 10%;
    }

    .img-label {
      bottom: -35%;
    }
  }

  .plan-dots {
    position: absolute;
    left: 6%;
    width: 370px;
    height: 170px;
    z-index: 9999;
    bottom: 0;
    list-style: none;
    padding: 0;
    margin: 0;

    &::before {
      content: '';
      background: url(/images/planDots/plan_base.png);
      opacity: 0.8;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
    }

    li {
      width: 363px;
      overflow: hidden;
      margin: 0;
      height: auto;
      display: block;
      transition: opacity 0.25s ease-in-out;
    }

    li:not(.slick-active) {
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-down-medium {
    width: 100%;

    .main-image {
      position: relative;
      height: 80vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
      padding: 0;

      img {
        transform: rotate(270deg);
        height: 16vh;
        position: absolute;
        max-width: initial;
        width: auto !important;
      }
    }
  }
}
