@import '../../styles/Vars';
@import '../../styles/Helpers';

.App {
  display: flex;
  /* min-height: 100vh; */
  flex-direction: column;
}

main {
  flex: 1;
}

.content-padding-y {
  padding-bottom: 100px;
  padding-top: 100px;
}

.content-padding-x {
  padding-left: 30px;
  padding-right: 30px;

  @include media-breakpoint-up-medium {
    padding-left: 98px;
    padding-right: 98px;
  }

  @include media-breakpoint-up-huge {
    padding-left: 200px;
    padding-right: 200px;
  }
}

.content-padding-left {
  padding-left: 30px;

  @include media-breakpoint-up-medium {
    padding-left: 98px;
  }

  @include media-breakpoint-up-huge {
    padding-left: 200px;
  }
}

.content-padding-right {
  padding-right: 30px;

  @include media-breakpoint-up-medium {
    padding-right: 98px;
  }

  @include media-breakpoint-up-huge {
    padding-right: 200px;
  }
}

.content-padding-small-x {
  padding-left: 5%;
  padding-right: 5%;
}

.inner-page-wrapper {
  padding-top: 150px;
  padding-left: 30px;
  padding-right: 30px;

  @include media-breakpoint-up-medium {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.inner-text {
  font-size: $font-size-12;
  font-weight: 300;
  line-height: 1.3;

  &.text-small {
    font-size: $font-size-4;
  }
}

.inner-pre-text {
  font-size: $font-size-28;
  line-height: 1;
}

.inner-label {
  font-size: $font-size-44;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;

  &.label-small {
    font-size: $font-size-34;
  }
}

.list-header {
  font-size: $font-size-4;
  font-weight: 600;
  padding-bottom: 15px;
}

.list-item {
  color: $dark-alternate;
  font-size: $font-size--2;
  text-transform: uppercase;
  padding-bottom: 10px;

  span {
    display: block;
    font-weight: 300;

    &:first-child {
      font-weight: 600;
    }
  }
}

.btn-icon-only {
  background: transparent;
  border: none;
}

.max-width-70 {
  max-width: 70%;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $light;
  opacity: 0.2;
  font-weight: 300;
  font-size: $font-size-4;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $light;
  opacity: 0.2;
  font-weight: 300;
  font-size: $font-size-4;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $light;
  opacity: 0.2;
  font-weight: 300;
  font-size: $font-size-4;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $light;
  opacity: 0.2;
  font-weight: 300;
  font-size: $font-size-4;
}

.form-group {
  input,
  textarea {
    background-color: transparent;
    border: 1px solid $secondary;
    height: auto;
    padding: 22px 30px;

    &:focus {
      background-color: transparent;
      color: $light;
    }
  }

  textarea {
    resize: none;
  }
}

button {
  &:active,
  &:focus {
    outline: none !important;
  }
}

.btn-secondary {
  text-transform: uppercase;
  font-size: $font-size-2;
  letter-spacing: 0.05rem;
}
